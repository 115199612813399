$app-primary-100     : #EDEBE9;
$app-primary-200     : #D4CFCA;
$app-primary-300     : #B2A9A0;
$app-primary-400     : #8F8174;
$app-primary-500     : #6D5B4A;
$app-primary-600     : #4D3722;
$app-primary-700     : #412F1D;
$app-primary-800     : #372718;
$app-primary-900     : #2C1F13;
$app-primary-1000    : #2C1F13;

$app-secondary-100   : #FEF6EE;
$app-secondary-200   : #FEEAD6;
$app-secondary-300   : #FCD9B6;
$app-secondary-400   : #FBC894;
$app-secondary-500   : #FAB774;
$app-secondary-600   : #F9A755;
$app-secondary-700   : #D48E48;
$app-secondary-800   : #B1773C;
$app-secondary-900   : #8E5F30;
$app-secondary-1000  : #704B26;

$app-success-100     : #EEFCD6;
$app-success-200     : #DAF9AF;
$app-success-300     : #BAED84;
$app-success-400     : #9ADC62;
$app-success-500     : #6FC635;
$app-success-600     : #54AA27;
$app-success-700     : #3D8E1B;
$app-success-800     : #297212;
$app-success-900     : #1C5F0B;

$app-info-100        : #CDEDFD;
$app-info-200        : #9DD7FB;
$app-info-300        : #6CB8F4;
$app-info-400        : #469AE8;
$app-info-500        : #156EDA;
$app-info-600        : #0E55BC;
$app-info-700        : #083F9D;
$app-info-800        : #042C7F;
$app-info-900        : #021F69;

$app-warning-100     : #FFF8CD;
$app-warning-200     : #FEEF9C;
$app-warning-300     : #FEE36B;
$app-warning-400     : #FED847;
$app-warning-500     : #FDC411;
$app-warning-600     : #D9A30C;
$app-warning-700     : #B58308;
$app-warning-800     : #926705;
$app-warning-900     : #795203;

$app-danger-100      : #FDE3D3;
$app-danger-200      : #FBC0A8;
$app-danger-300      : #F8967C;
$app-danger-400      : #F36E5A;
$app-danger-500      : #EE2D26;
$app-danger-600      : #CC1722;
$app-danger-700      : #AB0F25;
$app-danger-800      : #890926;
$app-danger-900      : #720526;

$app-neutral-100     : #ffffff;
$app-neutral-200     : #fcfcfc;
$app-neutral-300     : #f5f5f5;
$app-neutral-400     : #f0f0f0;
$app-neutral-500     : #d9d9d9;
$app-neutral-600     : #bfbfbf;
$app-neutral-700     : #8c8c8c;
$app-neutral-800     : #595959;
$app-neutral-900     : #454545;
$app-neutral-1000    : #262626;
$app-neutral-1100    : #1f1f1f;
$app-neutral-1200    : #141414;
$app-neutral-1300    : #000000;