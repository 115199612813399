.hover\:shadow:hover {
    box-shadow: #{$box-shadow};
}
.hover\:shadow-sm:hover {
    box-shadow: #{$box-shadow-sm};
}
.hover\:shadow-lg:hover {
    box-shadow: #{$box-shadow-lg};
}
.hover\:shadow-inset:hover {
    box-shadow: #{$box-shadow-inset};
}