/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html * {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

html h1, h2, h3, h4, h5 {
    font-weight: bold !important;
}

/**
 * Design System
 */
 @import "./scss/design-system";

 /**
  * Customize Bootstrap
  */
$prefix: 'bs-';
@import "./scss/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap";

/**
 * RemixIcon
 */
@import '../node_modules/remixicon/fonts/remixicon.css';

/**
 * Ng Select
 */
@import "~@ng-select/ng-select/themes/default.theme.css";

/**
 * Additional Styles
 */
@import "./scss/utility/shadow";
 
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4D3722;
    --bs-btn-border-color: #4D3722;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4D3722;
    --bs-btn-hover-border-color: #4D3722;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4D3722;
    --bs-btn-active-border-color: #4D3722;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #4D3722;
    --bs-btn-disabled-border-color: #4D3722;
}


.img-avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    object-fit: cover;
}

.img-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
}

.img-avatar-md {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-disable {
    background: $app-neutral-400 !important;
    border: 1px solid $app-neutral-600 !important;
    color: $app-neutral-700 !important;
}

.text-sm {
    font-size: 0.8rem !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: 300;
    font-size: 0.85rem;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 300;
    font-size: 0.85rem;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-weight: 300;
    font-size: 0.85rem;
}

textarea.no-resize {
    resize: none;
}

.app-input-group-transparent {
    --bs-body-bg: rgba(255, 255, 255, 0.2); 
    --bs-body-color: #FFF;
    --bs-border-color: #FFF;
    
    .input-group-text {
        --bs-tertiary-bg: rgba(255, 255, 255, 0.2);
        border-right: none !important;
    }
    
    .form-control {
        padding-left: 0 !important;
        border-left: none !important;
        margin-left: 0 !important;
        
        &:focus {
            border: var(--bs-border-width) solid var(--bs-border-color);
        }
    }
    
    .form-control::placeholder {
        font-weight: 300;
        color: #FFF;
    }
    
    .form-control:-ms-input-placeholder {
        font-weight: 300;
        color: #FFF;
    }
    
    .form-control::-ms-input-placeholder {
        font-weight: 300;
        color: #FFF;
    }
}

.app-accordion {
    --bs-accordion-active-bg: none; 
    --bs-accordion-btn-focus-box-shadow: none;
    --#{$prefix}accordion-btn-icon: #{escape-svg(url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path></svg>'))};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg(url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM7 11H17V13H7V11Z"></path></svg>'))};
}

@each $key, $color in $theme-colors {
    .placeholder-#{$key}::placeholder {
        font-weight: 300;
        color: $color;
        opacity: 1;
    }
      
    .placeholder-#{$key}::-ms-input-placeholder {
        font-weight: 300;
        color: $color;
    }
}