$primary    : $app-primary-600;
$secondary  : $app-secondary-600;
$success    : $app-success-500;
$info       : $app-info-500;
$warning    : $app-warning-500;
$danger     : $app-danger-500;
$light      : $app-neutral-200;
$dark       : $app-neutral-1100;

$theme-colors: (
  "primary"   : $primary,
  "secondary" : $secondary,
  "success"   : $success,
  "info"      : $info,
  "warning"   : $warning,
  "danger"    : $danger,
  "light"     : $light,
  "dark"      : $dark
);

$new-theme-colors: (
    "primary-100"    : $app-primary-100,
    "primary-200"    : $app-primary-200,
    "primary-300"    : $app-primary-300,
    "primary-400"    : $app-primary-400,
    "primary-500"    : $app-primary-500,
    "primary-600"    : $app-primary-600,
    "primary-700"    : $app-primary-700,
    "primary-800"    : $app-primary-800,
    "primary-900"    : $app-primary-900,
    "primary-1000"   : $app-primary-1000,
    
    "secondary-100"  : $app-secondary-100,
    "secondary-200"  : $app-secondary-200,
    "secondary-300"  : $app-secondary-300,
    "secondary-400"  : $app-secondary-400,
    "secondary-500"  : $app-secondary-500,
    "secondary-600"  : $app-secondary-600,
    "secondary-700"  : $app-secondary-700,
    "secondary-800"  : $app-secondary-800,
    "secondary-900"  : $app-secondary-900,
    "secondary-1000" : $app-secondary-1000,
    
    "success-100"    : $app-success-100,
    "success-200"    : $app-success-200,
    "success-300"    : $app-success-300,
    "success-400"    : $app-success-400,
    "success-500"    : $app-success-500,
    "success-600"    : $app-success-600,
    "success-700"    : $app-success-700,
    "success-800"    : $app-success-800,
    "success-900"    : $app-success-900,
    
    "info-100"       : $app-info-100,
    "info-200"       : $app-info-200,
    "info-300"       : $app-info-300,
    "info-400"       : $app-info-400,
    "info-500"       : $app-info-500,
    "info-600"       : $app-info-600,
    "info-700"       : $app-info-700,
    "info-800"       : $app-info-800,
    "info-900"       : $app-info-900,
    
    "warning-100"    : $app-warning-100,
    "warning-200"    : $app-warning-200,
    "warning-300"    : $app-warning-300,
    "warning-400"    : $app-warning-400,
    "warning-500"    : $app-warning-500,
    "warning-600"    : $app-warning-600,
    "warning-700"    : $app-warning-700,
    "warning-800"    : $app-warning-800,
    "warning-900"    : $app-warning-900,
    
    "danger-100"     : $app-danger-100,
    "danger-200"     : $app-danger-200,
    "danger-300"     : $app-danger-300,
    "danger-400"     : $app-danger-400,
    "danger-500"     : $app-danger-500,
    "danger-600"     : $app-danger-600,
    "danger-700"     : $app-danger-700,
    "danger-800"     : $app-danger-800,
    "danger-900"     : $app-danger-900,
    
    "neutral-100"    : $app-neutral-100,
    "neutral-200"    : $app-neutral-200,
    "neutral-300"    : $app-neutral-300,
    "neutral-400"    : $app-neutral-400,
    "neutral-500"    : $app-neutral-500,
    "neutral-600"    : $app-neutral-600,
    "neutral-700"    : $app-neutral-700,
    "neutral-800"    : $app-neutral-800,
    "neutral-900"    : $app-neutral-900,
    "neutral-1000"   : $app-neutral-1000,
    "neutral-1100"   : $app-neutral-1100,
    "neutral-1200"   : $app-neutral-1200,
    "neutral-1300"   : $app-neutral-1300,
);

$theme-colors: map-merge($theme-colors, $new-theme-colors);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1420px
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "31x9": calc(9 / 31 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "9x21": calc(21 / 9 * 100%),
  "9x31": calc(31 / 9 * 100%),
);

$input-placeholder-color: map-get($new-theme-colors, 'neutral-600');

$form-check-input-border: var(--#{$prefix}border-width) solid $app-neutral-700;

$min-contrast-ratio: 2.5 !default;